import * as React from 'react'
import { Box } from '@chakra-ui/react'
import { CtaSimple } from '../components/ctaSimple/'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { NavBar } from '../components/navbar'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not Found" />
    <Box
      bgImage={fractalBg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <NavBar />
      <CtaSimple
        title="You just hit a route that doesn&#39;t exist... the sadness."
        titleSize="xl"
        cta="Back to homepage"
        linkTo="/"
      />
    </Box>
  </Layout>
)

export default NotFoundPage
